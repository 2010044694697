import { Payout } from "./entities/transaction";

export interface CurrencyObject {
	code: string,
	symbol: string
	supportsIncomingBankTransfers?: boolean,
	supportsOutgoingBankTransfers?: boolean
}

/*
*
* List of pages to be displayed for each main navigation item
 */
export interface ViewTemplate {
	visits?: boolean,
	rentRequests?: boolean,
	missingPayments?: boolean,
	lead?: boolean,
	leadNew?: boolean,
	listings?: boolean,
	users?: boolean,
	structures?: boolean;
	tenantsPayments?: boolean;
	landlordsPayments?: boolean;
	places?: boolean;
	cities?: boolean;
	settings?: boolean;
	seoSettings?: boolean;
	customerCare?: boolean;
	agents?: boolean;
	confirmedRequests?: boolean;
	refundRequests?: boolean;
	notification?: boolean
	contracts?: boolean
}

export enum SeasonalPriceType {
	CLASSIC = 0, // Shows min price in the period
	AVG = 1, // Shows average price in the period
	BASE_PRICE = 2, // Shows base price in the period
	MAX_PRICE = 3 // Shows max price in the period
}

export enum UserQualityEnum {
	BAD = 0,
	NORMAL = 1,
	GOOD = 2,
}

export enum CheckInOutType {
	MONTHLY = 1,
	DAILY = 2,
	WEEKLY = 3,
	FORTNIGHTLY = 4,
	AVAILABILITY = 5,
	FORTNIGHTLY_AVAILABILITY = 6
}

export enum SeasonalPriceDisplayType {
	CLASSIC = 0, // Default
	AVG = 1 // Show avg of
}

export enum LandlordFeePaymentType {
	DEDUCED = 1,
	MANUALLY_PAID = 2,
}

export enum PaymentAccountAction {
	CREATE = 0,
	EDIT = 1,
	REMOVE = 2
}

/*Stipe payment accounts */

export enum BusinessType {
	PRIVATE,
	COMPANY
}

export interface PaymentAccount {
	id?: number;
	businessType?: BusinessType;
	nickname?: string;
	mcc?: string;
	url: string;
	tosAccepted?: boolean;
	firstName: string;
	lastName: string;
	gender: number;
	dateOfBirth: number | Date;
	dateOfBirthISO?: Date;
	companyName?: string;
	email?: string;
	addressLine1: string; // Via e Numero
	addressNumber: number;
	addressLine2: string;
	addressPostalCode: string; // CAP
	addressCity: string; // Comune
	addressState: string; // Provincia
	addressCountryId?: number;
	phonePrefixId?: number;
	phonePrefix?: string;
	phone?: string;
	taxId?: string; // P.IVA
	stripeAccountId?: string;
	persons?: Person[];
	details?: PaymentMethods[];
	documents?: AccountDocuments[];
	isDefault: boolean; // indicates if the current payment profile is favourite (default)
}

export interface AccountDocuments {
	id: number,
	type: DocumentType,
	url?: string,
	tmpFileName?: string
}

export enum DocumentType {
	GENERIC = 0,
	ID_FRONT = 1,
	ID_BACK = 2
}

export enum DefaultPayment {
	PROFILE = 0,
	METHOD = 1
}

export interface PaymentMethods {
	id?: number;
	paymentAccountId: number;
	type: number;
	nickname: string;
	bankCountryId: number;
	accountHolderName: string;
	accountHolderType: number; // 0 -> Private, 1 -> Company
	bankRoutingNumber: string;
	bankAccountNumber: string;
	stripeId?: string;
	bankAccountCurrency: CurrencyObject;
	isDefault: boolean; // indicates if the current payment method is favourite (default)
	listingsCount: number; // indicates the number of listing associated to this payment method
	rentRequestsCount: number; // indicates the number of rent requests associated to this payment method
	transfersCount: number; // indicates the number of transfers made with this payment method
	userId: number; // used to set favorites (only if admin, based on token)
	paymentAccount?: PaymentAccount; // used in Rent (PaymentAccountDetailsDTO)
}

export interface Person {
	id: number;
	paymentAccountId: number;
	dateOfBirth: number;
	dateOfBirthISO: Date;
	firstName: string;
	lastName: string;
	maidenName: string;
	gender: number;
	idNumber: string;
	idNumber2: string;
	nationalityId: number;
	percentOwnership: number;
	title: string;
	phonePrefixId: number;
	phonePrefix: string;
	phone: string;
	email: string;
	politicallyExposed: boolean;
	addressLine1: string;
	addressNumber: number;
	addressCountryId: number;
	addressCity: string;
	addressPostalCode: string;
	addressState: string;
	types: PersonTypeEnum[];
	stripeId: string;
}

export interface Transfer {
	id?: number;
	rentRequestId: number;
	paymentAccountDetailsId: number; // if -1 then manually paid
	amount?: number;
	currency: CurrencyObject;
	description: string;
	originalTransferId?: number;
	stripeId?: string;
	stripePayoutId?: string;
	status?: TransferStatusEnum;
	payout?: Payout;
	createdAt?: string;
}

export interface StripeTransactions {
	id?: number;
	amount: number;
	currency: CurrencyObject;
	rentRequestId: number;
	status: StripeTransactionStatus;
	name?: string
	description?: string
	url: string;
	createdBy?: number,
	createdAt?: number,
	updatedAt?: number
}

export enum StripeTransactionStatus {
	CREATED = 1,
	PAID = 2
}

export enum TransferStatusEnum {
	PENDING = 1, // Created on Stripe, pending in Connected Account balance
	COMPLETED = 2, // Paid to the Connected Account
	CREATED = 3, // Created locally, not present on Stripe
}


export enum TransferPayoutStatusEnum {
	CREATED = 1, // Created on Roomless, pending Stripe sync
	SENT = 2, // Created on Stripe and sent to the customer bank
	COMPLETED = 3, // Received by the customer's bank
	ERROR = 4, // Error during or after the payout
}

export enum PersonTypeEnum {
	NONE = 0,
	DIRECTOR = 1,
	EXECUTIVE = 2,
	OWNER = 3,
	REPRESENTATIVE = 4,
}

export enum PaymentModalPages {
	SETUP = 'SETUP',
	EDIT = 'EDIT',
	CREATE = 'CREATE',
	REMOVE = 'REMOVE',
	PAYMENT_PROFILE = 'PAYMENT_PROFILE',
	PAYMENT_METHOD = 'PAYMENT_METHOD',
}

/* Manage Refund different actions that can be performed*/
export enum RefundActionType {
	CREATE = 'CREATE',
	EDIT = 'EDIT',
	SEND = 'SEND',
	DELETE = 'DELETE'
}


export enum FollowupNotificationTypeEnum {
	NONE = 1,
	DEFAULT = 2,
	CUSTOM = 3
}

export enum VisitNotificationTypeEnum {
	NONE = 1,
	DEFAULT = 2,
}

export enum UserActionTypeEnum {
	RENT_REQUEST = 1,
	LEAD_REQUEST = 2
}